// src/components/Position.js
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, TextField, Tooltip, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import styled from 'styled-components';
import axios from 'axios';
import TokenDetails from './TokenDetails';
import { ethers } from 'ethers';

const PositionCard = styled(Card)`
  width: 100%;
  min-width: 400px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const Label = styled(Typography)`
  color: #666;
  font-weight: 500;
`;

const Value = styled(Typography)`
  font-weight: 700;
`;

const RangeIndicator = styled(Typography)`
  font-weight: 700;
  color: ${props => props.inRange ? '#4caf50' : '#f44336'};
`;

const StyledTextField = styled(TextField)`
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

function tickToPrice(tick, decimals0, decimals1) {
  console.log(`[Position] tickToPrice called with: tick=${tick}, decimals0=${decimals0}, decimals1=${decimals1}`);
  
  // If decimals are missing, use reasonable defaults for most Uniswap pairs
  const numericTick = parseFloat(tick);
  const numericDecimals0 = !isNaN(parseFloat(decimals0)) ? parseFloat(decimals0) : 18;  // Default to 18 if undefined
  const numericDecimals1 = !isNaN(parseFloat(decimals1)) ? parseFloat(decimals1) : 18;  // Default to 18 if undefined
  
  console.log(`[Position] tickToPrice using values: numericTick=${numericTick}, numericDecimals0=${numericDecimals0}, numericDecimals1=${numericDecimals1}`);
  
  let diff = numericDecimals1 - numericDecimals0;
  const powResult = Math.pow(1.0001, numericTick);
  console.log(`[Position] tickToPrice intermediate values: diff=${diff}, 1.0001^tick=${powResult}`);
  
  const result = powResult / (Math.pow(10, diff));
  console.log(`[Position] tickToPrice final result: ${result}`);
  
  return result;
}

function isStablecoin(symbol){
  const array=['USDC', 'USDT', 'DAI'];
  return array.includes(symbol);
}

function formatToSignificantFigures(value, significantFigures = 4) {
  console.log(`[Position] formatToSignificantFigures called with: value=${value}`);
  
  if (value === 0) {
    console.log(`[Position] formatToSignificantFigures returning early with 0`);
    return "0.0000";
  }
  
  // Check for NaN and invalid values
  if (isNaN(value) || !isFinite(value)) {
    console.log(`[Position] formatToSignificantFigures encountered invalid value: ${value}`);
    return "0.0000";
  }
  
  const exponent = Math.floor(Math.log10(Math.abs(value)));
  const factor = Math.pow(10, significantFigures - 1 - exponent);
  const result = (Math.round(value * factor) / factor).toFixed(Math.max(0, significantFigures - 1 - exponent));
  
  console.log(`[Position] formatToSignificantFigures result: ${result}`);
  return result;
}

const Position = ({ position, id, name, tickLower, tickUpper, totalUSD, currentPrice, amount0, amount1, token0Decimals, token1Decimals, feeAPR, isHedgeable, hedgeStatus, hedgeReason, fetchHedges, network, provider }) => {
  const [alertText, setAlertText] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [isShow, setIsShow] = useState(1);
  // Unused variables commented out
  // const liquidity = position.liquidity;
  // const pool = position.pool;
  const token0addr = position.token0;
  const token1addr = position.token1;
  const token0Symbol = position.token0Symbol;
  const token1Symbol = position.token1Symbol;
  
  // Use explicitly passed decimals rather than trying to get them from position
  // with fallbacks to reasonable defaults if they're undefined
  const decimals0 = token0Decimals || position.decimals0 || 18;
  const decimals1 = token1Decimals || position.decimals1 || 18;
  
  const tickSpacing = position.tickSpacing;
  
  // Use the inRange property directly from the position object
  // This was calculated correctly in PositionInfo.js based on current tick
  const inRange = position.inRange;
  
  // Log to verify that we're using the correct range status
  console.log(`[Position] Position ${id} tick: ${position.tick}, range: ${tickLower} to ${tickUpper}, in range: ${inRange}`);

  useEffect(() => {
    let intervalId;
  
    const updateHedges = () => {
      console.log(`[Position] Updating hedges for position ${id} with status: ${hedgeStatus}`);
      fetchHedges();
    };
  
    if (hedgeStatus === "starting" || hedgeStatus === "stopping") {
      console.log(`[Position] Setting rapid interval (20s) for position ${id} with status: ${hedgeStatus}`);
      intervalId = setInterval(updateHedges, 20000);
    } else {
      console.log(`[Position] Setting standard interval (60s) for position ${id}`);
      intervalId = setInterval(updateHedges, 60000);
    }
  
    return () => {
      clearInterval(intervalId); // сleanup on component unmount or status change
      console.log(`[Position] Cleared interval for position ${id}`);
    };
  }, [hedgeStatus, fetchHedges, id]);

  const networkConfig = network;

  const [hedgeSchema, setHedgeSchema] = useState([
    {
      "fr": 0.45,
      "sl": 0
    },
    {
      "fr": 0.20,
      "sl": 0.0005
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0.20,
      "sl": 0.0005
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0.15,
      "sl": 0.0005
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0,
      "sl": 0
    }
  ]);

  const [showHedge, setShowHedge] = useState(false);

  // Add logging before price calculation
  console.log(`[Position] Raw tick values: tickLower=${tickLower}, tickUpper=${tickUpper}`);
  console.log(`[Position] Decimal values: decimals0=${decimals0}, decimals1=${decimals1}`);
  
  const priceLower = tickToPrice(tickLower, decimals0, decimals1);
  const priceUpper = tickToPrice(tickUpper, decimals0, decimals1);
  
  // Add logging after price calculation
  console.log(`[Position] Calculated prices: priceLower=${priceLower}, priceUpper=${priceUpper}`);
  console.log(`[Position] Formatted prices: priceLowerFormatted=${formatToSignificantFigures(priceLower)}, priceUpperFormatted=${formatToSignificantFigures(priceUpper)}`);
  
  // const ButtonName = hedgeStatus !== "" ? 'Stop Hedge' : 'Hedge';

  async function approveAutoExitContract(networkConfig, signer) {
    console.log(`[Position] Approving AutoExit contract for position ${id}`);
    if (!networkConfig.uniswapNFTManagerAddress || !ethers.utils.isAddress(networkConfig.uniswapNFTManagerAddress)) {
        throw new Error("Invalid or missing Uniswap NFT Manager Address.");
    }

    if (!networkConfig.AutoExitAddress || !ethers.utils.isAddress(networkConfig.AutoExitAddress)) {
        throw new Error("Invalid or missing AutoExit Address.");
    }

    const contract = new ethers.Contract(
        networkConfig.uniswapNFTManagerAddress,
        [
            'function setApprovalForAll(address operator, bool approved) public'
        ],
        signer
    );

    await contract.setApprovalForAll(networkConfig.AutoExitAddress, true);
    console.log(`[Position] AutoExit contract approved successfully for position ${id}`);
}


async function configureToken(
  networkConfig,
  signer,
  tokenId,
  token0Symbol,
  tickLower,
  tickUpper
) {
  console.log(`[Position] Configuring token ${tokenId} (${token0Symbol})`);
    const token0IsStable = isStablecoin(token0Symbol);
    const token0Swap = !token0IsStable;
    const token1Swap = token0IsStable;

    const tickLowerInt = parseInt(tickLower, 10);
    const tickUpperInt = parseInt(tickUpper, 10);

    if (tickLowerInt < -8388608 || tickLowerInt > 8388607) {
        throw new Error("tickLower is out of int24 range");
    }
    if (tickUpperInt < -8388608 || tickUpperInt > 8388607) {
        throw new Error("tickUpper is out of int24 range");
    }

    const isActive = true;
    if (isActive && tickLowerInt >= tickUpperInt) {
        throw new Error("InvalidConfig: tickLower must be less than tickUpper when active");
    }

    const config = [
        isActive,
        token0Swap,
        token1Swap,
        tickLowerInt,
        tickUpperInt,
        ethers.BigNumber.from("368934881474191032"),
        ethers.BigNumber.from("368934881474191032"),
        false,
        ethers.BigNumber.from("184467440737095516"),
    ];

    //console.log(config);

    const contract = new ethers.Contract(
        networkConfig.AutoExitAddress,
        [
            'function configToken(uint256 tokenId, (bool,bool,bool,int24,int24,uint64,uint64,bool,uint64) config) external',
        ],
        signer
    );

    try {
          const data = contract.interface.encodeFunctionData("configToken", [
            tokenId,
            config
        ]);
        //console.log(data);
    
        //console.log("Submitting transaction...");
        const tx = await contract.configToken(tokenId, config, { gasLimit: 1003867 });
        await tx.wait();
        console.log(`[Position] Token ${tokenId} configured successfully, tx hash: ${tx.hash}`);
    } catch (error) {
        console.error("Error configuring token:", error.message);

        if (error?.data?.message) {
            console.error("Revert reason:", error.data.message);
        }

        if (error.message.includes("Unauthorized")) {
            throw new Error("You are not the owner of the token.");
        } else if (error.message.includes("InvalidConfig")) {
            throw new Error("tickLower must be less than tickUpper when active.");
        }

        throw error;
    }
  }
  
  const handleStopHedge = async (positionId) => {
    console.log(`[Position] Stopping hedge for position ${positionId}`);
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.post(
        "https://api.divedao.io/api/stop_hedging",
        {
          chain: networkConfig.chain,
          position_id: Number(positionId),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        console.log(`[Position] Hedge stopped successfully for position ${positionId}`);
        alert("Hedge stopped successfully!");
        fetchHedges(); // Обновить статус хеджа
      } else {
        console.log(`[Position] Failed to stop hedge for position ${positionId}`);
        alert("Failed to stop hedge.");
        fetchHedges();
      }
    } catch (error) {
      console.error(`[Position] Error stopping hedge for position ${positionId}:`, error);
    }
  };
  
  const handleApproveHedge = async (positionId) => {
    console.log(`[Position] Starting hedge approval for position ${positionId}`);
    try {
      const signer = provider.getSigner();
      await approveAutoExitContract(networkConfig, signer);
      await configureToken(
        networkConfig,
        signer,
        positionId,
        token0Symbol,
        tickLower,
        tickUpper
      );
  
      const token = localStorage.getItem("authToken");
      const response = await axios.post(
        "https://api.divedao.io/api/hedge",
        {
          chain: networkConfig.chain,
          position_id: Number(positionId),
          schema: hedgeSchema,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        console.log(`[Position] Hedge approved successfully for position ${positionId}`);
        alert("Hedge approved successfully!");
        fetchHedges();
      } else {
        console.log(`[Position] Failed to approve hedge for position ${positionId}`);
        alert("Failed to approve hedge.");
      }
    } catch (error) {
      console.error("Error approving hedge:", error);
    }
  };

  // Add a new state for confirmation dialog
  const [showConfirmation, setShowConfirmation] = useState(false);

  const getButtonConfig = (status) => {
    console.log(`[Position] Getting button config for position ${id}, hedge status: "${status}"`);
    
    // Define a complete button configuration object
    switch (status) {
      case "active":
        return { 
          text: "Stop Hedge", 
          tooltip: "Stop the hedge position",
          color: "secondary", 
          disabled: false,
          action: () => setShowConfirmation(true)  // Show confirmation before stopping
        };
      case "hedging":
        return { 
          text: "Hedging", 
          tooltip: "Position is being hedged",
          color: "primary", 
          disabled: true,
          action: null
        };
      case "ready":
        return { 
          text: "Hedge", 
          tooltip: "Create a hedge for this position",
          color: "primary", 
          disabled: false,
          action: () => handleApproveHedge(id)
        };
      case "starting":
        return { 
          text: "Starting...", 
          tooltip: "Hedge is being initiated",
          color: "primary", 
          disabled: true,
          action: null
        };
      case "stopping":
        return { 
          text: "Stopping...", 
          tooltip: "Hedge is being terminated",
          color: "secondary", 
          disabled: true,
          action: null
        };
      case "stopped":
        return { 
          text: "Hedge", 
          tooltip: "Create a hedge for this position",
          color: "primary", 
          disabled: false,
          action: () => handleApproveHedge(id)
        };
      default:
        return { 
          text: "Hedge", 
          tooltip: "Create a hedge for this position",
          color: "primary", 
          disabled: false,
          action: () => handleApproveHedge(id)
        };
    }
  };

  // Replace handleButtonState with getButtonConfig
  const buttonConfig = getButtonConfig(hedgeStatus);

  return (
    isShow === 1 && (
    <PositionCard>
      <CardContent>
        <InfoRow>
          <Label variant="subtitle1">Token Pair:</Label>
          <a href={`https://app.uniswap.org/positions/v3/${network.chain}/${id}`} target="_blank" rel="noreferrer" variant="subtitle1">{name +" "+ (position.fee/10000) + "%" }</a>
        </InfoRow>
        <InfoRow>
          <TokenDetails tokenAddress={token0addr} symbol={token0Symbol} network={network} />
          <Value variant="subtitle1">{formatToSignificantFigures(amount0)}</Value>
        </InfoRow>
        <InfoRow>
          <TokenDetails tokenAddress={token1addr} symbol={token1Symbol} network={network} />
          <Value variant="subtitle1">{formatToSignificantFigures(amount1)}</Value>
        </InfoRow>
        
        <InfoRow>
          <Label variant="subtitle1">Range Status:</Label>
          <RangeIndicator variant="subtitle1" inRange={inRange}>
            {inRange ? '🟢 In Range' : '🔴 Out of Range'}
          </RangeIndicator>
        </InfoRow>
        <InfoRow>
          <Label variant="subtitle1">Price Range:</Label>
          <Value variant="subtitle1">
            ${formatToSignificantFigures(priceLower)} - ${formatToSignificantFigures(priceUpper)}
          </Value>
        </InfoRow>
        <InfoRow>
          <Label variant="subtitle1">Current Price:</Label>
          <Value variant="subtitle1">
            ${formatToSignificantFigures(currentPrice)}
          </Value>
        </InfoRow>
        <InfoRow>
          <Label variant="subtitle1">Position Size:</Label>
          <Value variant="subtitle1">
            ${totalUSD.toFixed(2)}
          </Value>
        </InfoRow>

        {isHedgeable ? (
          <div>
            <Tooltip title={buttonConfig.tooltip} arrow>
              <span> {/* Wrapper span to allow tooltip on disabled button */}
                <Button
                  variant="contained"
                  color={buttonConfig.color}
                  onClick={buttonConfig.action}
                  disabled={buttonConfig.disabled}
                  aria-label={buttonConfig.tooltip}
                >
                  {buttonConfig.text}
                </Button>
              </span>
            </Tooltip>
            <IconButton
              onClick={() => setShowHedge(!showHedge)}
              style={{ marginLeft: 10 }}
              aria-label="Hedge settings"
            >
              <SettingsIcon />
            </IconButton>
            
            {/* Confirmation Dialog for stopping hedge */}
            {showConfirmation && (
              <div style={{ marginTop: 10, padding: 10, border: '1px solid #f44336', borderRadius: 4 }}>
                <Typography variant="body2">
                  Are you sure you want to stop hedging this position?
                </Typography>
                <div style={{ marginTop: 10, display: 'flex', gap: 10 }}>
                  <Button 
                    variant="contained" 
                    color="error" 
                    size="small"
                    onClick={() => {
                      handleStopHedge(id);
                      setShowConfirmation(false);
                    }}
                  >
                    Yes, Stop Hedge
                  </Button>
                  <Button 
                    variant="outlined" 
                    size="small"
                    onClick={() => setShowConfirmation(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
            
            {hedgeStatus === "stopped" && hedgeReason && (
              <Typography variant="body2" style={{ color: "orange", marginTop: 10 }}>
                {hedgeReason}
              </Typography>
            )}
            {showHedge && (
              <div>
                <StyledTextField
                  multiline
                  rows={8}
                  variant="outlined"
                  value={JSON.stringify(hedgeSchema, null, 2)}
                  onChange={(e) => setHedgeSchema(JSON.parse(e.target.value))}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowHedge(false)}
                  style={{ marginTop: 10 }}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
          ) : (
            <Typography variant="body2" color="textSecondary">
              Cannot hedge this position.
            </Typography>
          )}
      </CardContent>
    </PositionCard>
    )
  );
};

export default Position;

import React from 'react';
import styled from 'styled-components';

const TokenContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const TokenLink = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const TokenDetails = ({ tokenAddress, symbol, network }) => {
  return (
    <TokenContainer>
      <TokenLink href={`${network.explorerURL}/token/${tokenAddress}`} target="_blank" rel="noopener noreferrer">
        {symbol}
      </TokenLink>
    </TokenContainer>
  );
};

export default TokenDetails;

import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button } from '@mui/material';
import styled from 'styled-components';
import axios from 'axios';

const SaveButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`;

function SettingsModal({ open, onClose, cexName, onFinish }) {
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');

  const handleSave = async () => {
    const payload = {
      cex: cexName,
      cex_key: apiKey,
      cex_secret: apiSecret,
    };

    try {
      const token = localStorage.getItem('authToken');
      await axios.post('https://api.divedao.io/api/set_cex', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onClose();
      onFinish();
    } catch (error) {
      console.error('Error saving API key and secret:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`${cexName} API Details`}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="apiKey"
          label="API Key"
          type="password"
          fullWidth
          variant="outlined"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
        />
        <TextField
          margin="dense"
          id="apiSecret"
          label="API Secret"
          type="password"
          fullWidth
          variant="outlined"
          value={apiSecret}
          onChange={(e) => setApiSecret(e.target.value)}
        />
        <SaveButton variant="contained" color="primary" onClick={handleSave}>
          Save
        </SaveButton>
      </DialogContent>
    </Dialog>
  );
}

export default SettingsModal;

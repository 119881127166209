// src/App.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import WalletModal from './components/WalletModal';
import SettingsModal from './components/SettingsModal';
import PositionInfo from './components/PositionInfo';
import axios from 'axios';
import { ethers } from 'ethers';
import { SiweMessage } from 'siwe';

const AppContainer = styled.div`
  font-family: 'Arial', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const MainContent = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
`;

const supportedNetworks = [
  { chainId: 42161, name: 'Arbitrum One' },
  { chainId: 10, name: 'Optimism' },
  { chainId: 137, name: 'Polygon' },
  { chainId: 8453, name: 'Base' },
];

const getNetworkConfig = (chainId) => {
  switch (chainId) {
    case 137:
      return {
        uniswapFactoryAddress: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
        uniswapNFTManagerAddress: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
        AutoExitAddress: "0xCe98E8973102bD64e866bc67E40bA39f1fd1239e",
        explorerURL: "https://polygonscan.com",
        chain: "polygon"
      };
    case 10:
      return {
        uniswapFactoryAddress: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
        uniswapNFTManagerAddress: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
        AutoExitAddress: "0x2df8687cc775236d3822d422b7b5170782e67057",
        explorerURL: "https://optimistic.etherscan.io",
        chain: "optimism"
      };
    case 42161:
      return {
        uniswapFactoryAddress: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
        uniswapNFTManagerAddress: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
        AutoExitAddress: "0x6c6707F5E24B916BB3e45C5822466a88b315F5Ec",
        explorerURL: "https://arbiscan.io",
        chain: "arbitrum"
      };
    case 8453:
      return {
        uniswapFactoryAddress: '0x33128a8fC17869897dcE68Ed026d694621f6FDfD',
        uniswapNFTManagerAddress: '0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1',
        AutoExitAddress: "0x062cc630A73A02aBc195A3D3d10bEf802FbFcaef",
        explorerURL: "https://basescan.org",
        chain: "base"
      };
    default:
      throw new Error('Unsupported network');
  }
};

function App() {
  const [walletConnected, setWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [supportedAssets, setSupportedAssets] = useState([]);
  const [currentProvider, setCurrentProvider] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [cexName, setCexName] = useState('');
  const [cexStatus, setCexStatus] = useState('');
  const [currentNetwork, setCurrentNetwork] = useState(supportedNetworks[0].chainId);
  const [networkConfig, setNetworkConfig] = useState(getNetworkConfig(currentNetwork));
  const [currentCexName, setCurrentCexName] = useState('');

  // Define fetchSupportedAssets before it's used in useEffect
  const fetchSupportedAssets = async () => {
    console.log("[App] Fetching supported assets");
    const token = localStorage.getItem('authToken');
    const response = await axios.get('https://api.divedao.io/api/get_supported_assets', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      setSupportedAssets(response.data);
      console.log(`[App] Supported assets fetched: ${response.data.assets.length} assets`);
    } else {
      throw new Error('Failed to fetch supported assets');
    }
  };

  const fetchCexStatus = async () => {
    console.log("[App] Fetching CEX status");
    const token = localStorage.getItem('authToken');
    const response = await axios.get('https://api.divedao.io/api/get_cex_status', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    if (response.status === 200) {
      const { cex, status } = response.data;
      console.log(`[App] CEX status fetched: ${cex || 'none'}, status: ${status || 'none'}`);
      if (cex === null) {
        setShowSettingsModal(true); 
      } else {
        setShowSettingsModal(false);
        setCexName(cex);
        setCexStatus(status);
      }
    } else {
      throw new Error('Failed to fetch CEX status');
    }
  };

  axios.interceptors.response.use(
    response => response,
    async error => {
      if (error.response && error.response.status === 401) {
        if (!isConnected) {
          localStorage.removeItem('authToken');
          setWalletConnected(false);
          setIsConnected(false);
          setWalletAddress('');
        }
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const checkIfConnected = async () => {
      const savedAddress = localStorage.getItem('walletAddress');
      const token = localStorage.getItem('authToken');
      if (savedAddress && token) {
        console.log('[App] Saved wallet found, attempting to reconnect');
        try {
          setWalletConnected(true);
          setWalletAddress(savedAddress);
          
          if (typeof window.ethereum !== 'undefined') {
            console.log('[App] Web3 provider found, initializing');
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const network = await provider.getNetwork();
            
            // Check if network is supported
            const isSupported = supportedNetworks.some(net => net.chainId === network.chainId);
            const networkId = isSupported ? network.chainId : 42161; // Default to Arbitrum if not supported
            console.log(`[App] Network detected: ${network.name} (${network.chainId}), supported: ${isSupported}`);
            
            setCurrentNetwork(networkId);
            setNetworkConfig(getNetworkConfig(networkId));
            setCurrentProvider(provider);
            await fetchSupportedAssets();
            await fetchCexStatus();
            setIsConnected(true);
            console.log('[App] Connection successfully restored');
          } else {
            // Handle case when MetaMask is not installed
            console.log('[App] MetaMask not installed, using default network (Arbitrum)');
            setCurrentNetwork(42161); // Default to Arbitrum
            setNetworkConfig(getNetworkConfig(42161));
            await fetchSupportedAssets();
            await fetchCexStatus();
            setIsConnected(true);
          }
        } catch (error) {
          console.error('[App] Error during connection check:', error);
          localStorage.removeItem('authToken');
          setWalletConnected(false);
          setIsConnected(false);
          setWalletAddress('');
        }
      } else {
        console.log('[App] No saved wallet found, awaiting connection');
      }
    };

    checkIfConnected();

    // Listen for account and network changes
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        console.log(`[App] Accounts changed: ${accounts.length ? accounts[0] : 'none'}`);
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
          setWalletConnected(true);
          localStorage.setItem('walletAddress', accounts[0]);
        } else {
          setWalletAddress('');
          setWalletConnected(false);
          localStorage.removeItem('walletAddress');
        }
      });

      window.ethereum.on('chainChanged', async (chainId) => {
        console.log(`[App] Chain changed to: ${parseInt(chainId, 16)}`);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();
        setCurrentNetwork(network.chainId);
        setNetworkConfig(getNetworkConfig(network.chainId));
      });
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', () => {});
        window.ethereum.removeListener('chainChanged', () => {});
      }
    };
  }, [walletAddress]);

  useEffect(() => {
    const initNetwork = async () => {
      if (typeof window.ethereum !== 'undefined') {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const network = await provider.getNetwork();
          handleNetworkChange(network.chainId);

          window.ethereum.on('chainChanged', (chainIdHex) => {
            const chainId = parseInt(chainIdHex, 16);
            handleNetworkChange(chainId);
          });
        } catch (error) {
          console.error('Error initializing network:', error);
          // Set to default supported network
          setCurrentNetwork(42161); // Arbitrum
          setNetworkConfig(getNetworkConfig(42161));
        }
      } else {
        console.log('MetaMask is not installed');
        // Use default network for UI if MetaMask is not available
        setCurrentNetwork(42161); // Arbitrum
        setNetworkConfig(getNetworkConfig(42161));
      }
    };

    initNetwork();

    return () => {
      if (window.ethereum && window.ethereum.removeListener) {
        window.ethereum.removeListener('chainChanged', handleNetworkChange);
      }
    };
  }, []);

  const handleNetworkChange = async (newChainId) => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        // Check if the network is supported
        const isSupported = supportedNetworks.some(network => network.chainId === newChainId);
        
        if (!isSupported) {
          console.log('Network not supported, switching to default (Arbitrum)');
          // Switch to a default network (e.g., Arbitrum) if the current one is not supported
          newChainId = 42161; // Arbitrum One
        }
        
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${newChainId.toString(16)}` }],
        });
        
        setCurrentNetwork(newChainId);
        setNetworkConfig(getNetworkConfig(newChainId));
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        setCurrentProvider(provider);
      } catch (error) {
        console.error('Failed to switch network:', error);
        // If switching fails, at least update the UI to a supported network
        const defaultChainId = 42161; // Arbitrum One
        setCurrentNetwork(defaultChainId);
        setNetworkConfig(getNetworkConfig(defaultChainId));
        alert('Please switch to a supported network (Arbitrum, Optimism, or Polygon) in your wallet.');
      }
    }
  };

  const handleConnectWallet = async (address) => {
    setWalletConnected(true);
    setWalletAddress(address);
    setShowWalletModal(false);
    await authenticateWithWallet(address);
  };

  const authenticateWithWallet = async (address) => {
    console.log(`[App] Starting authentication for address: ${address}`);
    try {
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const network = await provider.getNetwork();
        console.log(`[App] Authenticating on network: ${network.name} (${network.chainId})`);

        const nonce = Math.floor(Date.now() / 1000).toString();

        var trueAddress = ethers.utils.getAddress(address);

        const message = new SiweMessage({
          domain: window.location.origin,
          address: trueAddress,
          statement: 'Sign in with Ethereum to the app.',
          uri: window.location.origin,
          version: '1',
          chainId: await signer.getChainId(),
          nonce: nonce,
        });

        console.log('[App] Requesting message signature');
        const signedMessage = await signer.signMessage(message.prepareMessage());

        console.log('[App] Sending authentication request to API');
        const response = await axios.post('https://api.divedao.io/api/auth', {
          message: message.prepareMessage(),
          signature: signedMessage,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status !== 200) {
          throw new Error('Authentication failed');
        }

        const authData = response.data;
        console.log('[App] Authentication successful, token received');

        localStorage.setItem('authToken', authData.token);
        
        await fetchSupportedAssets();
        await fetchCexStatus();
        setIsConnected(true);
        console.log('[App] Setup complete, user fully authenticated');
      } else {
        alert('Please install MetaMask!');
      }
    } catch (error) {
      console.error('[App] Error connecting to MetaMask or authenticating:', error);
      alert('Failed to connect and authenticate. Please try again.');
    }
  };

  const handleDisconnectWallet = () => {
    setWalletConnected(false);
    setWalletAddress('');
    localStorage.removeItem('walletAddress');
    localStorage.removeItem('authToken');
  };

  return (
    <AppContainer>
    <Header 
      walletConnected={walletConnected}
      walletAddress={walletAddress}
      onConnectClick={() => setShowWalletModal(true)}
      onDisconnectClick={handleDisconnectWallet}
      onSettingsClick={() => setShowSettingsModal(true)}
      onCurCex={(cexName)=>setCurrentCexName(cexName)}
      currentNetwork={currentNetwork}
      onNetworkChange={handleNetworkChange}
      supportedNetworks={supportedNetworks}
      cexName={cexName}
      cexStatus={cexStatus}
    />
    <MainContent>
      {walletConnected && currentProvider && isConnected && (
        <PositionInfo
          isConnected={isConnected}
          address={walletAddress}
          supportedAssets={supportedAssets}
          provider={currentProvider}
          networkConfig={networkConfig}
        />
      )}
    </MainContent>
    <WalletModal
      open={showWalletModal}
      onClose={() => setShowWalletModal(false)}
      onConnect={handleConnectWallet}
      setCurrentProvider={setCurrentProvider}
    />
    {isConnected && (
      <SettingsModal
        open={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        cexName={currentCexName}
        onFinish={() => fetchCexStatus()}
      />
    )} 
  </AppContainer>
  );
}

export default App;

/* global BigInt */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Position from './Position';
import { Card } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { ethers } from 'ethers';
import axios from 'axios';
import { Typography } from '@mui/material';
//import { contract } from 'web3/lib/commonjs/eth.exports';

const Q96 = Math.pow(2, 96);
const Q128 = BigInt(2) ** BigInt(128);

const LoaderCard = styled(Card)`
  width: 100%;
  max-width: 900px;
  min-width: 400px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 auto;
`;

const LoadingCard = () => (
  <LoaderCard>
    <Skeleton variant="rectangular" height={150} />
    <Skeleton variant="text" width="80%" />
    <Skeleton variant="text" width="60%" />
    <Skeleton variant="text" width="90%" />
  </LoaderCard>
);

const uniPoolAbi = [
  {
    "inputs": [],
    "name": "slot0",
    "outputs": [
      { "internalType": "uint160", "name": "sqrtPriceX96", "type": "uint160" },
      { "internalType": "int24", "name": "tick", "type": "int24" }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "feeGrowthGlobal0X128",
    "outputs": [
      { "internalType": "uint256", "name": "", "type": "uint256" }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "feeGrowthGlobal1X128",
    "outputs": [
      { "internalType": "uint256", "name": "", "type": "uint256" }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "int24", "name": "tick", "type": "int24" }],
    "name": "ticks",
    "outputs": [
      { "internalType": "uint128", "name": "liquidityGross", "type": "uint128" },
      { "internalType": "int128", "name": "liquidityNet", "type": "int128" },
      { "internalType": "uint256", "name": "feeGrowthOutside0X128", "type": "uint256" },
      { "internalType": "uint256", "name": "feeGrowthOutside1X128", "type": "uint256" }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];

function isStablecoin(symbol){
  const array=['USDC', 'USDT', 'DAI'];
  return array.includes(symbol);
}


const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const PositionList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex-direction: column;
`;

// Add a container for positions with responsive styling
const PositionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
`;

const PageHeading = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
`;

// Add a container for the no positions message
const EmptyStateContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 40px auto;
  text-align: center;
  color: #666;
`;

async function getTokenInfo(tokenAddress, provider) {
  const tokenContract = new ethers.Contract(
    tokenAddress,
    [
      'function symbol() view returns (string)',
      'function decimals() public view returns (uint8)'
    ],
    provider
  );

  
  return [await tokenContract.symbol(), await tokenContract.decimals()];
}

function tokenAmountsAt(price, liquidity, tickLower, tickUpper, token0Decimals, token1Decimals, sqrtPriceX96) {
  console.log(`[PositionInfo] tokenAmountsAt inputs: price=${price}, liquidity=${liquidity}, tickLower=${tickLower}, tickUpper=${tickUpper}, token0Decimals=${token0Decimals}, token1Decimals=${token1Decimals}`);
  
  if (liquidity === 0n) {
    return [0n, 0n, 0, 0];
  }

  const Q96 = 2n ** 96n;
  let isInRange = true;

  const sqrtRatioA = (Math.sqrt(1.0001 ** tickLower));
  const sqrtRatioB = (Math.sqrt(1.0001 ** tickUpper));

  const ratio = Number(sqrtPriceX96) / Number(Q96);
  const squaredRatio = ratio ** 2;
  const currentTick = Math.floor(Math.log(squaredRatio) / Math.log(1.0001));
  
  console.log(`[PositionInfo] tokenAmountsAt calculated values: sqrtRatioA=${sqrtRatioA}, sqrtRatioB=${sqrtRatioB}, ratio=${ratio}, currentTick=${currentTick}`);

  let amount0Wei = 0n;
  let amount1Wei = 0n;

  if (currentTick < tickLower) {
    amount0Wei = (Number(liquidity) * (Number(sqrtRatioB) - Number(sqrtRatioA)) / (Number(sqrtRatioA) * Number(sqrtRatioB)));
    isInRange = false;
  } else if (currentTick >= tickUpper) {
    amount1Wei = (Number(liquidity) * (Number(sqrtRatioB) - Number(sqrtRatioA)));
    isInRange = false;
  } else {
    amount0Wei = (Number(liquidity) * (Number(sqrtRatioB) - Number(ratio)) / (Number(ratio) * Number(sqrtRatioB)));
    amount1Wei = (Number(liquidity) * (Number(ratio) - Number(sqrtRatioA)));
  }

  // Updated method to calculate human-readable amounts
  // Simply divide by the appropriate power of 10 based on token decimals
  const amount0Human = Number(Math.abs(Number(amount0Wei))) / (10 ** token0Decimals);
  const amount1Human = Number(Math.abs(Number(amount1Wei))) / (10 ** token1Decimals);
  
  console.log(`[PositionInfo] tokenAmountsAt raw amounts: amount0Wei=${amount0Wei}, amount1Wei=${amount1Wei}`);
  console.log(`[PositionInfo] tokenAmountsAt human amounts: amount0Human=${amount0Human}, amount1Human=${amount1Human}, isInRange=${isInRange}`);

  return [amount0Wei, amount1Wei, amount0Human, amount1Human, isInRange];
}  

async function getFees(poolContract, tickLower, tickUpper, fees0, fees1, liquidity, token0Decimals, token1Decimals, currentTick) {
  const tickLow = await poolContract.ticks(tickLower);
  const tickHigh = await poolContract.ticks(tickUpper);

  const feeGrowthGlobal0 = await poolContract.feeGrowthGlobal0X128();
  const feeGrowthGlobal1 = await poolContract.feeGrowthGlobal1X128();
  const tickLowerFeeGrowthOutside0 = tickLow.feeGrowthOutside0X128;
  const tickUpperFeeGrowthOutside0 = tickHigh.feeGrowthOutside0X128;
  const feeGrowthInside0 = fees0;
  const tickLowerFeeGrowthOutside1 = tickLow.feeGrowthOutside1X128;
  const tickUpperFeeGrowthOutside1 = tickHigh.feeGrowthOutside1X128;
  const feeGrowthInside1 = fees1;

  const Q128 = BigInt(2 ** 128);

  let tickUpperFeeGrowthAbove0, tickUpperFeeGrowthAbove1;
  if (currentTick >= tickUpper) {
    tickUpperFeeGrowthAbove0 = BigInt(feeGrowthGlobal0) - BigInt(tickUpperFeeGrowthOutside0);
    tickUpperFeeGrowthAbove1 = BigInt(feeGrowthGlobal1) - BigInt(tickUpperFeeGrowthOutside1);
  } else {
    tickUpperFeeGrowthAbove0 = BigInt(tickUpperFeeGrowthOutside0);
    tickUpperFeeGrowthAbove1 = BigInt(tickUpperFeeGrowthOutside1);
  }

  let tickLowerFeeGrowthBelow0, tickLowerFeeGrowthBelow1;
  if (currentTick >= tickLower) {
    tickLowerFeeGrowthBelow0 = BigInt(tickLowerFeeGrowthOutside0);
    tickLowerFeeGrowthBelow1 = BigInt(tickLowerFeeGrowthOutside1);
  } else {
    tickLowerFeeGrowthBelow0 = BigInt(feeGrowthGlobal0) - BigInt(tickLowerFeeGrowthOutside0);
    tickLowerFeeGrowthBelow1 = BigInt(feeGrowthGlobal1) - BigInt(tickLowerFeeGrowthOutside1);
  }

  const frT10 = BigInt(feeGrowthGlobal0) - tickLowerFeeGrowthBelow0 - tickUpperFeeGrowthAbove0;
  const frT11 = BigInt(feeGrowthGlobal1) - tickLowerFeeGrowthBelow1 - tickUpperFeeGrowthAbove1;

  const uncollectedFees0 = (BigInt(liquidity) * (frT10 - BigInt(feeGrowthInside0))) / Q128;
  const uncollectedFees1 = (BigInt(liquidity) * (frT11 - BigInt(feeGrowthInside1))) / Q128;

  const uncollectedFeesAdjusted0 = Number(uncollectedFees0) / (10 ** token0Decimals);
  const uncollectedFeesAdjusted1 = Number(uncollectedFees1) / (10 ** token1Decimals);

  return {
    token0: uncollectedFeesAdjusted0,
    token1: uncollectedFeesAdjusted1
  };
}

function PositionInfo({ isConnected, address, supportedAssets, provider, networkConfig }) {
  const [positions, setPositions] = useState([]);
  const [hedges, setHedges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchHedges = async () => {
    console.log("[PositionInfo] fetchHedges started");
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get('https://api.divedao.io/api/get_hedges', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setHedges(response.data.hedges);
      console.log(`[PositionInfo] Hedges fetched: ${response.data.hedges.length}`);

    } catch (error) {
      console.error('[PositionInfo] Error fetching hedges:', error);
    }
  };

  useEffect(() => {
    console.log("[PositionInfo] useEffect triggered with dependencies: isConnected, networkConfig, address, provider");
    fetchHedges();
    fetchPositions();
  }, [isConnected, networkConfig, address, provider]);

  const fetchPositions = async () => {
    console.log("[PositionInfo] fetchPositions started");
    setIsLoading(true);
    try {

      const uniFactoryContract = new ethers.Contract(
        networkConfig.uniswapFactoryAddress,
        [
          {
            "inputs": [
              { "internalType": "address", "name": "", "type": "address" },
              { "internalType": "address", "name": "", "type": "address" },
              { "internalType": "uint24", "name": "", "type": "uint24" }
            ],
            "name": "getPool",
            "outputs": [
              { "internalType": "address", "name": "", "type": "address" }
            ],
            "stateMutability": "view",
            "type": "function"
          }
        ],
        provider
      );

      const contract = new ethers.Contract(
        networkConfig.uniswapNFTManagerAddress,
        [
          'function tokenOfOwnerByIndex(address _owner, uint256 _index) view returns (uint256)',
          'function positions(uint256 tokenId) external view returns (uint96 nonce, address operator, address token0, address token1, uint24 fee, int24 tickLower, int24 tickUpper, uint128 liquidity, uint256 feeGrowthInside0LastX128, uint256 feeGrowthInside1LastX128, uint128 tokensOwed0, uint128 tokensOwed1)',
          'function balanceOf(address owner) external view returns (uint256)',
          'function setApprovalForAll(address operator, bool approved) public'
        ],
        provider
      );

      const positionCount = await contract.balanceOf(address);

      const tokenIdPromises = [];
      for (let i = 0; i < positionCount; i++) {
        tokenIdPromises.push(contract.tokenOfOwnerByIndex(address, i));
      }
      const tokenIds = await Promise.all(tokenIdPromises);

      const positionPromises = tokenIds.map(tokenId => contract.positions(tokenId));
      const allPositions = await Promise.all(positionPromises);

      const combinedPositions = allPositions.map((posData, i) => ({
        positionId: tokenIds[i],
        ...posData
      }));

    const validPositions = combinedPositions.filter(p => p.liquidity > 0n);

    console.log(`[PositionInfo] Found ${validPositions.length} valid positions with liquidity`);
  
      const resultPromises = validPositions.map(async (positionData) => {
        const token0Info = await getTokenInfo(positionData.token0, provider);
        const token1Info = await getTokenInfo(positionData.token1, provider);
  
        const [token0Symbol, token0Decimals] = token0Info;
        const [token1Symbol, token1Decimals] = token1Info;
  
        const poolAddress = await uniFactoryContract.getPool(
          positionData.token0,
          positionData.token1,
          positionData.fee
        );
        
        const poolContract = new ethers.Contract(poolAddress, uniPoolAbi, provider);
        const slot0 = await poolContract.slot0();
        const sqrtPriceX96 = slot0.sqrtPriceX96;
        const price = (sqrtPriceX96 / Q96) ** 2 * 10 ** token0Decimals / 10 ** token1Decimals;
        
        // Add logging about price calculation
        console.log(`[PositionInfo] Price calculation: sqrtPriceX96=${sqrtPriceX96}, price=${price}`);
        
        // Compute token amounts and fees, etc.
        const [amount0Wei, amount1Wei, amount0, amount1, inRange] = tokenAmountsAt(
          price,
          BigInt(positionData.liquidity),
          positionData.tickLower,
          positionData.tickUpper,
          token0Decimals,
          token1Decimals,
          sqrtPriceX96
        );
  
        const uncollectedFees = await getFees(
          poolContract,
          positionData.tickLower,
          positionData.tickUpper,
          positionData.feeGrowthInside0LastX128,
          positionData.feeGrowthInside1LastX128,
          positionData.liquidity,
          token0Decimals,
          token1Decimals,
          slot0.tick
        );
  
        // Calculate normalized price and log the calculation
        let normalizedPrice;
        if (isStablecoin(token0Symbol)) {
          normalizedPrice = 1 / price;
          console.log(`[PositionInfo] Token0 ${token0Symbol} is stablecoin, normalizedPrice = 1/price = ${normalizedPrice}`);
        } else {
          normalizedPrice = price;
          console.log(`[PositionInfo] Token0 ${token0Symbol} is not stablecoin, normalizedPrice = price = ${normalizedPrice}`);
        }
        
        console.log(`[PositionInfo] Final normalizedPrice = ${normalizedPrice}`);

        let isShow = 1;
        if ((amount0 === 0) && (amount1 === 0)) {
          isShow = 0;
        }

        console.log(`[PositionInfo] Position ${positionData.positionId} current tick: ${slot0.tick}, range: ${positionData.tickLower} to ${positionData.tickUpper}, in range: ${inRange}`);

        return {
          ...positionData,
          token0Symbol,
          token1Symbol,
          token0Decimals,
          token1Decimals,
          price,
          normalizedPrice,
          amount0,
          amount1,
          uncollectedFees,
          inRange,
          isShow,
          tick: slot0.tick
        };
  
      });
      
      const positionsData = await Promise.all(resultPromises);
      
      setPositions(positionsData);
      console.log(`[PositionInfo] Positions processed and set: ${positionsData.length}`);
      setIsLoading(false);
      
    } catch (error) {
      setIsLoading(false);
      console.error('[PositionInfo] Error fetching positions:', error);
    }
  };

  return (
    <div>
      <PageHeading>Your Uniswap V3 Positions</PageHeading>
      {isLoading ? (
        <LoadingCard />
      ) : positions.length === 0 ? (
        <EmptyStateContainer>
          <Typography variant="h6">No open positions found</Typography>
        </EmptyStateContainer>
      ) : (
        <PositionsContainer>
          {positions.map((position, index) => {
            const { positionId, token0Symbol, token1Symbol, tickLower, tickUpper } = position;

            console.log(`[PositionInfo] Rendering position ${index}: ${positionId} (${token0Symbol}/${token1Symbol})`);

            const name = `${token0Symbol} / ${token1Symbol}`;
            const tokens = [token0Symbol, token1Symbol];

            const hedge = hedges.find(h => h.position_id === Number(positionId));
            const hedgeStatus = hedge ? hedge.status : "";
            const hedgeReason = hedge ? hedge.reason : "";

            console.log(`[PositionInfo] Position ${positionId} hedge status: ${hedgeStatus || "none"}`);

            const isHedgeable = supportedAssets.assets.some(
              asset => tokens.some(token => !isStablecoin(token) && token === asset)
            );
            
            // Add detailed logging for position value calculation
            console.log(`[PositionInfo] Position ${positionId} calculation inputs:`);
            console.log(`[PositionInfo] - token0: ${token0Symbol}, amount0: ${position.amount0}, isStablecoin: ${isStablecoin(token0Symbol)}`);
            console.log(`[PositionInfo] - token1: ${token1Symbol}, amount1: ${position.amount1}, isStablecoin: ${isStablecoin(token1Symbol)}`);
            console.log(`[PositionInfo] - normalizedPrice: ${position.normalizedPrice}`);
            
            // Modified USD calculation approach
            // If both tokens are non-stablecoins, this needs special handling
            let usdAmount0, usdAmount1;
            
            if (isStablecoin(token0Symbol)) {
              // Token0 is a stablecoin (value is its face value)
              usdAmount0 = position.amount0;
              // Token1 is non-stablecoin, value = amount * price
              usdAmount1 = position.amount1 * position.normalizedPrice;
            } else if (isStablecoin(token1Symbol)) {
              // Token0 is non-stablecoin, value = amount * price
              usdAmount0 = position.amount0 * position.normalizedPrice;
              // Token1 is a stablecoin (value is its face value)
              usdAmount1 = position.amount1;
            } else {
              // Both are non-stablecoins - need a different approach
              // Use the normalizedPrice for both but be careful not to double-count
              usdAmount0 = position.amount0 * position.normalizedPrice;
              // For token1, we need a price in USD terms
              usdAmount1 = position.amount1; // Simplified for now
              console.log(`[PositionInfo] Warning: Both tokens are non-stablecoins, calculation may be inaccurate`);
            }
            
            console.log(`[PositionInfo] USD values calculated:`);
            console.log(`[PositionInfo] - usdAmount0: ${usdAmount0}`);
            console.log(`[PositionInfo] - usdAmount1: ${usdAmount1}`);
            
            const totalUSD = usdAmount0 + usdAmount1;

            console.log(`[PositionInfo] Position ${positionId} total value: $${totalUSD.toFixed(2)}`);

            return (
              <Position
                key={index}
                position={position}
                id={positionId}
                name={name}
                tickLower={tickLower}
                tickUpper={tickUpper}
                totalUSD={totalUSD}
                currentPrice={position.normalizedPrice}
                amount0={position.amount0}
                amount1={position.amount1}
                token0Decimals={position.token0Decimals}
                token1Decimals={position.token1Decimals}
                feeAPR={0}
                isHedgeable={isHedgeable}
                hedgeStatus={hedgeStatus}
                hedgeReason={hedgeReason}
                fetchHedges={fetchHedges}
                network={networkConfig}
                provider={provider}
              />
            );
        })}
        </PositionsContainer>
      )}
    </div>
  );
}

export default PositionInfo;